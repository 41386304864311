import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Grid,
} from '@mui/material';

import { categoryService } from '../../services';

import MyDropzone from '../../components/Dropzone';
import { validator } from '../../utils/index';

import { useSnack } from '../../hooks/index';

export default function CategoryFormPage() {

  const navigate = useNavigate();

  const { setSnack } = useSnack();

  const [destaque, setDestaque] = useState(null);
  const [errors, setErrors] = useState({
    name: '',
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name');
    const nameError = validator.name(name, true);
    if (!nameError) {
      try {
        const token = await categoryService.create({
          name,
          imagem: destaque != null ? destaque.uploaded._id : null
        });
        navigate('/dashboard/categorias');
        return setSnack({ message: 'Categoria cadastrada', type: 'success' });
      } catch (e) {
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        name: nameError,
      });
    }
  }

  const onDropFiles = async (fil) => {
    console.log("Chegou arquivos");
    setDestaque(fil[0]);
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Queijos | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Adicionar Queijo
        </Typography>
        <Box
          sx={{
            mt: 2,
            bgcolor: 'transparent',
          }}
          component="form"
          onSubmit={onSubmit}
        >
          <Grid
            container
            fullWidth
            spacing={5}
          >
            <Grid item xl={12}>
              <MyDropzone
                maxFiles={1}
                onDropFiles={onDropFiles}
              />
            </Grid>
            <Grid item xl={12}>
              <TextField
                name="name"
                label="Nome da Categoria"
                required
                fullWidth
                error={errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xl={12}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </>
  );
}
