import { ENDPOINTS } from '../constants';
import { parseResponse, defaultHeaders } from '../utils';
import { Queijo } from '../models';

class QueijoService {
    async getAll (query)  {
    const url = ENDPOINTS.parse(ENDPOINTS.QUEIJO, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Queijo);
  }

  async create(data) {
    const queijo = new Queijo(data);
    if (!queijo.isValid()) throw new Error('Invalid queijo');

    const response = await fetch(ENDPOINTS.QUEIJO, {
      method: 'POST',
      headers: defaultHeaders(),
      body: queijo.json(),
    });

    return parseResponse(response);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.QUEIJO_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Queijo);
  }

  async update(data) {
    const queijo = new Queijo(data);
    if (!queijo.isValid(true)) throw new Error('Invalid queijo');

    const response = await fetch(ENDPOINTS.QUEIJO_ID(queijo._id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: queijo.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.QUEIJO_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  async updateDestaque(id, enable) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.QUEIJO_ID(id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: JSON.stringify({ destaque: !!enable }),
    });

    return parseResponse(response, Queijo);
  }

}

export default new QueijoService();
