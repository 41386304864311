import { ENDPOINTS } from '../constants';

class UploadService {
  async image(imageData) {
    const token = sessionStorage.getItem('token');
    const headers = {
      authorization: `Bearer ${token}`,
    };

    try {
      const body = new FormData();
      body.append('image', imageData);
      const response = await fetch(ENDPOINTS.FILE, {
        method: 'POST',
        body,
        headers,
      }).then((e) => e.json());
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export default new UploadService();
