import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  IconButton,
  TableCell,
  ListItemAvatar,
  ListItemText,
  TableRow,
  Chip,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Category } from '../../../models';

function CategoryRow({ category, onRemove }) {

  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(`/dashboard/categorias/${category._id}`);
  };

  return (
    <TableRow>
      <TableCell>{category.imagem && <Avatar src={category.imagem.caminho} />}</TableCell>
      <TableCell>{category.name}</TableCell>
      <TableCell>{moment(category.updatedDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
      <TableCell>
        <IconButton
          onClick={handleClick}
        >
          <EditIcon
            color="primary"
          />
        </IconButton>
        <IconButton
          onClick={() => onRemove(category._id)}
        >
          <DeleteIcon color="primary" />
        </IconButton>
      </TableCell>

    </TableRow>
  );
}

CategoryRow.propTypes = {
  category: PropTypes.instanceOf(Category).isRequired,
};

export default CategoryRow;