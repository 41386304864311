
export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3005';


export const DASHBOARD = `${SERVER_URL}/dashboard`;
export const AUTH = `${SERVER_URL}/token`;
export const QUEIJO = `${SERVER_URL}/queijo`;
export const QUEIJO_ID = (id) => `${QUEIJO}/${id}`;
export const CATEGORY = `${SERVER_URL}/category`;
export const CATEGORY_ID = (id) => `${CATEGORY}/${id}`;
export const FILE = `${SERVER_URL}/file`;
export const RECEITA = `${SERVER_URL}/receita`;
export const RECEITA_ID = (id) => `${RECEITA}/${id}`;
export const HARMONIZACAO = `${SERVER_URL}/harmonizacao`;
export const HARMONIZACAO_ID = (id) => `${HARMONIZACAO}/${id}`;
export const PRODUTO = `${SERVER_URL}/produto`;
export const PRODUTO_ID = (id) => `${PRODUTO}/${id}`;


export function parse(endpoint, query) {
    query = query || {};
    try {
        let url = `${endpoint}?`;
        const queryInfo = [];
        Object.entries(query).forEach(([key, value]) => {
            queryInfo.push(`${key}=${value}`);
        });
        url += queryInfo.join('&');

        return url;
    } catch (err) {
        return endpoint;
    }
}