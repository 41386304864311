import { ENDPOINTS } from '../constants';
import { parseResponse, defaultHeaders } from '../utils';
import { Produto } from '../models';

class ProdutoService {
    async getAll (query)  {
    const url = ENDPOINTS.parse(ENDPOINTS.PRODUTO, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Produto);
  }

  async create(data) {
    const produto = new Produto(data);
    if (!produto.isValid()) throw new Error('Invalid Produto');

    const response = await fetch(ENDPOINTS.PRODUTO, {
      method: 'POST',
      headers: defaultHeaders(),
      body: produto.json(),
    });

    return parseResponse(response);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.PRODUTO_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Produto);
  }

  async update(data) {
    const produto = new Produto(data);
    if (!produto.isValid(true)) throw new Error('Invalid produto');

    const response = await fetch(ENDPOINTS.PRODUTO_ID(produto._id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: produto.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.PRODUTO_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

}

export default new ProdutoService();
