import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { User } from '../models';

export default function useAuth() {
  const initialize = () => {
    const newState = {
      token: null,
      user: null,
    };
    let token = sessionStorage.getItem('token');
    const remember = localStorage.getItem('remember');
    if (remember && !token) {
      token = localStorage.getItem('token');
      sessionStorage.setItem('token', token);
    }

    if (token) {
      newState.token = token;
      const decodedToken = jwtDecode(token);
      console.log(decodedToken);
      const newUser = new User(decodedToken || {});

      if (newUser.isValid()) {
        sessionStorage.setItem('user', newUser.json());
        newState.user = newUser;
      }
    }

    return newState;
  };

  const [state, setState] = useState(initialize());

  const setToken = (userToken, remember) => {
    if (userToken == null) {
      localStorage.removeItem('token');
      localStorage.removeItem('remember');
      sessionStorage.clear();
      return setState({
        token: null,
        user: null,
      });
    }

    if (remember) {
      localStorage.setItem('remember', 1);
      localStorage.setItem('token', userToken);
    }

    sessionStorage.setItem('token', userToken);

    const newState = {
      token: null,
      user: null,
    };
    if (userToken) {
      newState.token = userToken;
      const decodedToken = jwtDecode(userToken);
      const newUser = new User(decodedToken || {});

      if (newUser.isValid()) {
        sessionStorage.setItem('user', newUser.json());
        newState.user = newUser;
      }
    }

    return setState(newState);
  };

  const setUser = (data) => {
    const user = data instanceof User ? data : new User(data || {});

    if (user.isValid()) {
      sessionStorage.setItem('user', user.json());
      setState((oldState) => ({
        ...oldState,
        user,
      }));
    }
  };

  return {
    setToken,
    setUser,
    user: state.user,
    token: state.token,
  };
}
