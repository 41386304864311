import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { categoryService } from '../../services';

import MyDropzone from '../../components/Dropzone';
import { validator } from '../../utils/index';

import { useSnack } from '../../hooks/index';

export default function CategoryEditFormPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { setSnack } = useSnack();

  const [category, setCategory] = useState({
    loading: true,
    data: []
  });
  const [destaque, setDestaque] = useState(null);
  const [errors, setErrors] = useState({
    name: '',
  });

  useEffect(() => {
    async function fetchProfile() {
      const loadedData = (await categoryService.get(id)) || {};
      console.log(loadedData);
      setCategory({
        loading: false,
        data: loadedData
      });
    }

    fetchProfile();
  }, [id]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name');
    const nameError = validator.name(name, true);
    if (!nameError) {
      try {
        const token = await categoryService.update({
          _id: id,
          id,
          name,
          imagem: destaque != null ? destaque.uploaded._id : (category.data.imagem ? category.data.imagem._id : null)
        });
        navigate('/dashboard/categorias');
        return setSnack({ message: 'Categoria atualizado!', type: 'success' });;
      } catch (e) {
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        name: nameError,
      });
    }
  }

  const onDropFiles = async (fil) => {
    console.log("Chegou arquivos");
    setDestaque(fil[0]);
  }

  if (category.loading) {
    return (
      <>
        <Helmet>
          <title> Dashboard: Categorias | Painel Interativo do Queijo </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Editar Categoria
          </Typography>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
              textAlign: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Categorias | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Editar Categoria
        </Typography>
        <Box
          sx={{
            mt: 2,
            bgcolor: 'transparent',
          }}
          component="form"
          onSubmit={onSubmit}
        >
          <Grid
            container
            fullWidth
            spacing={5}
          >
            <Grid item xl={12}>
              <MyDropzone
                maxFiles={1}
                onDropFiles={onDropFiles}
                previews={category.data.imagem}
              />
            </Grid>
            <Grid item xl={12}>
              <TextField
                name="name"
                label="Nome da Categoria"
                required
                fullWidth
                defaultValue={category.data.name}
                error={errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xl={12}>
              <Button variant="contained" type="submit">
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </>
  );
}
