import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import './dropzone.css';

import { LinearProgress } from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';

import { uploadService } from '../services/index';

export default function MyDropzone(props) {
  const { maxFiles, onDropFiles, previews } = props;
  const [file, setFiles] = useState([]);
  const [thumbs, setThumbs] = useState([]);
  const [sending, setSending] = useState(false);

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 200,
    padding: 4,
    boxSizing: 'border-box',
    backgroundColor: 'rgba(145, 158, 171, 0.16)'
  };

  const thumbInner = {
    display: 'block',
    minWidth: 0,
    overflow: 'hidden',
  };

  const img = {
    display: 'block',
    width: '100%',
    height: 'auto'
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const focusedStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'image/*': [] },
    maxFiles,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        uploaded: false,
        sending: true,
        error: null,
      })));
    }
  });

  useEffect(() => {
    async function uploadImages() {
      await Promise.all(file.map(async (f, index) => {
        if (f.ulploaded) return;
        setSending(true);
        const image = await uploadService.image(f);
        setFiles((prevState) => {
          prevState[index].uploaded = image;
          prevState[index].sending = false;
          return prevState;
        });
      }));
      onDropFiles(file);
      setSending(false);
    };
    if (file.length > 0) {
      uploadImages();
    }
  }, [file]);

  useEffect(() => {
    setThumbs((prevState) => {
      if (previews && !sending && file.length === 0) {
        console.log("Tem preview já enviado")
        if (Array.isArray(previews)) {
          return [];
        }

        const prev = [(
          (<div style={thumb} key={previews.name}>
            <div style={thumbInner}>
              <img
                src={previews.caminho}
                style={img}
                alt={previews.filename}
              />
            </div>
          </div>)
        )];

        return prev;
      }

      return file.map(f => (
        <div style={thumb} key={f.name}>
          <div style={thumbInner}>
            <img
              src={f.preview}
              style={img}
              alt={f.filename}
              // Revoke data uri after image is loaded
              onLoad={() => { URL.revokeObjectURL(f.preview) }}
            />
            {f.sending && <div style={{ textAlign: 'center' }}><LinearProgress /></div>}
            {f.uploaded && <div style={{ textAlign: 'center' }}><DoneIcon color="primary" /></div>}
          </div>
        </div>
      ));

    });
  }, [previews, sending]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Arraste uma imagem aqui ou clique para adicionar.</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </div>
  );
}