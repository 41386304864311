import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';

import QueijoPage from './pages/queijo/QueijoPage';
import QueijoFormPage from './pages/queijo/QueijoFormPage';
import QueijoEditFormPage from './pages/queijo/QueijoEditFormPage';

import CategoryPage from './pages/category/CategoryPage';
import CategoryFormPage from './pages/category/CategoryFormPage';
import CategoryEditFormPage from './pages/category/CategoryEditFormPage';

import ReceitaPage from './pages/receita/ReceitaPage';
import ReceitaFormPage from './pages/receita/ReceitaFormPage';
import ReceitaEditFormPage from './pages/receita/ReceitaEditFormPage';

import HarmonizacaoPage from './pages/harmonizacao/HarmonizacaoPage';
import HarmonizacaoFormPage from './pages/harmonizacao/HarmonizacaoFormPage';
import HarmonizacaoEditFormPage from './pages/harmonizacao/HarmonizacaoEditFormPage';

import ProdutoPage from './pages/produto/ProdutoPage';
import ProdutoFormPage from './pages/produto/ProdutoFormPage';
import ProdutoEditFormPage from './pages/produto/ProdutoEditFormPage';

import { useAuth } from './hooks';

// ----------------------------------------------------------------------

export default function Router() {
  const { token, user, setToken } = useAuth();

  console.log(user);

  let routes = [];

  if(token){
    routes = [
      {
        path: '/dashboard',
        element: <DashboardLayout setToken={setToken} user={user} />,
        children: [
          { element: <Navigate to="/dashboard/app" />, index: true },
          { path: 'app', element: <DashboardAppPage /> },
          { path: 'user', element: <UserPage /> },
          { path: 'products', element: <ProductsPage /> },
          { path: 'blog', element: <BlogPage /> },
          { path: 'queijos', element: <QueijoPage />},
          { path: 'queijos/novo', element: <QueijoFormPage /> },
          { path: 'queijos/:id', element: <QueijoEditFormPage /> },
          { path: 'categorias', element: <CategoryPage />},
          { path: 'categorias/nova', element: <CategoryFormPage />},
          { path: 'categorias/:id', element: <CategoryEditFormPage /> },
          { path: 'receitas', element: <ReceitaPage /> },
          { path: 'receitas/nova', element: <ReceitaFormPage /> },
          { path: 'receitas/:id', element: <ReceitaEditFormPage /> },
          { path: 'harmonizacoes', element: <HarmonizacaoPage /> },
          { path: 'harmonizacoes/nova', element: <HarmonizacaoFormPage /> },
          { path: 'harmonizacoes/:id', element: <HarmonizacaoEditFormPage /> },
          { path: 'produtos/', element: <ProdutoPage /> },
          { path: 'produtos/novo', element: <ProdutoFormPage /> },
          { path: 'produtos/:id', element: <ProdutoEditFormPage /> },
        ],
      },
      {
        path: 'login',
        element: <LoginPage setToken={setToken} />,
      },
      {
        element: <SimpleLayout />,
        children: [
          { element: <Navigate to="/dashboard/app" />, index: true },
          { path: '404', element: <Page404 /> },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ];
  } else {
    routes = [
      {
        path: '/',
        element: <LoginPage setToken={setToken} />,
      },
      {
        path: 'login',
        element: <LoginPage setToken={setToken} />,
      },
      { path: '404', element: <Page404 /> },
      {
        path: '*',
        element: <Navigate to="/404" replace />,
      },
    ]; 
  }

  return useRoutes(routes);
}
