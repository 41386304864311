import { ENDPOINTS } from '../constants';
import { parseResponse, defaultHeaders } from '../utils';
import { Dashboard } from '../models';

class DashboardData {

    async dashboard() {
        const response = await fetch(ENDPOINTS.DASHBOARD, {
            method: 'GET',
            headers: defaultHeaders()
        });
        return parseResponse(response, Dashboard);
    }

}

export default new DashboardData();
