import { ENDPOINTS } from '../constants';
import { parseResponse, defaultHeaders } from '../utils';
import { Receita } from '../models';

class ReceitaService {
    async getAll (query)  {
    const url = ENDPOINTS.parse(ENDPOINTS.RECEITA, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Receita);
  }

  async create(data) {
    const receita = new Receita(data);
    if (!receita.isValid()) throw new Error('Invalid queijo');

    const response = await fetch(ENDPOINTS.RECEITA, {
      method: 'POST',
      headers: defaultHeaders(),
      body: receita.json(),
    });

    return parseResponse(response);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.RECEITA_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Receita);
  }

  async update(data) {
    const receita = new Receita(data);
    if (!receita.isValid(true)) throw new Error('Invalid queijo');

    const response = await fetch(ENDPOINTS.RECEITA_ID(receita._id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: receita.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.RECEITA_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

}

export default new ReceitaService();
