import { SnackbarProvider, useSnackbar } from 'notistack';

export default function useSnack() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const saveSnack = async ({ type, message, key }) => {
    enqueueSnackbar(message || 'Servidor indisponível', { key, variant: type || 'error' });
  };

  return { setSnack: saveSnack, closeSnackbar };
}
