import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Box, Button, Divider, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import { validator } from '../../../utils';
import { authService } from '../../../services';
import { useSnack } from '../../../hooks';


// ----------------------------------------------------------------------

export default function LoginForm({ setToken }) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const { setSnack } = useSnack();

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get('remember'));
    const email = data.get('email');
    const password = data.get('password');
    const remember = data.get('remember') === 'on';

    const emailError = validator.email(email, true);
    const passwordError = validator.password(password, true);

    if (!emailError && !passwordError) {
      try {
        const token = await authService.login({ email, password });
        navigate('/dashboard');
        return setToken(token, remember);
      } catch (e) {
        console.log('aaaa');
        console.log(e.message);
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        email: emailError,
        password: passwordError,
      });
    }

  };

  return (
    <>
      <Stack spacing={3} marginBottom={3}>
        <Box component="form" width={'100%'} noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            name="email"
            fullWidth
            error={errors.email}
            helperText={errors.email}
            label="Email"
            sx={{
              marginBottom: 2
            }}
          />
          <TextField
            name="password"
            label="Senha"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            error={errors.password}
            marginBottom={3}
            helperText={errors.password}
            sx={{
              marginBottom: 2
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormGroup>
            <FormControlLabel control={<Checkbox name="remember" />} label="Lembrar-se" />
          </FormGroup>
          <Button fullWidth size="large" type="submit" variant="contained">
            Entrar
          </Button>
        </Box>
      </Stack>
    </>
  );
}
