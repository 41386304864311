class Dashboard {
    constructor(data) {
      this.queijos = data?.queijos;
      this.categorias = data?.categorias;
      this.receitas = data?.receitas;
      this.harmonizacoes = data?.harmonizacoes;
      this.produtos = data?.produtos;
    }
  
    /// Check if this is a valid user.
    isValid(checkId) {
      if (!this.queijos) return false;
      if (!this.categorias) return false;
      return true;
    }
  
    json() {
      return JSON.stringify(this);
    }
  }
  
  export default Dashboard;