import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Typography,
  Table,
  TableBody,
  Box,
  Button,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  TableFooter
} from '@mui/material';

import { produtoService } from '../../services';
import { DialogModal } from '../../components/dialog/index';

import { useSnack } from '../../hooks/index';

import { ProdutoRow } from './components';

export default function ReceitaPage() {

  const navigate = useNavigate();

  const { setSnack } = useSnack();

  const [page, setPage] = useState(0);
  const [remove, setRemove] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [produtos, setState] = useState({
    loading: true,
    reload: false,
    produtos: []
  });

  const [error, setError] = useState();

  console.log(produtos);

  useEffect(() => {
    if (error) return () => { };

    produtoService.getAll().then((produtos) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        reload: false,
        produtos,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error, produtos.reload]);

  const handleClick = () => {
    navigate('/dashboard/produtos/novo');
  };

  const handleRemove = async () => {
    setState((oldState) => ({
      ...oldState,
      reload: true,
    }));
    produtoService.delete(remove).then((success) => {
      if(success) {
        setSnack({
          type: 'success',
          message: 'Produto apagado com sucesso',
        });
        setRemove(false);
        navigate('/dashboard/produtos');
      } else {
        setSnack();
      }
    }).catch((error) => {
      setSnack({ message: error.message });
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  if (produtos.loading) {
    return (
      <>
        <Helmet>
          <title> Dashboard: Produtos | Painel Interativo do Queijo </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Produtos
          </Typography>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
              textAlign: 'center'
            }}
          >
            <CircularProgress />
          </Box>

        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Produtos | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Produtos
        </Typography>
        <Button variant="contained" onClick={handleClick}>
          + Adicionar
        </Button>
        <Box
          sx={{
            mt: 2,
            bgcolor: 'transparent',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Foto</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Queijos</TableCell>
                <TableCell>Última Atualização</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {produtos.produtos
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((produto) => (
                  <ProdutoRow produto={produto} key={produto._id} onRemove={setRemove} />
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={produtos.produtos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Resultados por página"
                  labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                  onPageChange={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 50]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
        {
          remove && (
        <DialogModal
          handleClose={() => setRemove(false)}
          onAccept={handleRemove}
        >
          Deseja realmente remover esse produto?
        </DialogModal>
        )
        }
      </Container>
    </>
  );
}
