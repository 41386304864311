import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';

import {
  Avatar,
  IconButton,
  TableCell,
  ListItemAvatar,
  ListItemText,
  TableRow,
  Chip,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Star } from '@mui/icons-material';

import { queijoService } from '../../../services/index';

import { Queijo } from '../../../models';

function QueijoRow({ queijo, onRemove }) {
  const [destaque, setDestaque] = useState(queijo.destaque);
  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(`/dashboard/queijos/${queijo._id}`);
  };

  const onDestaqueChange = () => {
    queijo.destaque = !destaque;
    queijoService.updateDestaque(queijo._id, !destaque);
    setDestaque(!destaque);
  };

  return (
    <TableRow>
      <TableCell>{queijo.imagem && <Avatar src={queijo.imagem.caminho} />}</TableCell>
      <TableCell>{queijo.name}</TableCell>
      <TableCell>{queijo.categorias.map((cat) => <Chip color="primary" label={cat.name} />)}</TableCell>
      <TableCell>
        <IconButton
          onClick={onDestaqueChange}
          color={destaque ? 'primary' : 'inherit'}
        >
          <Star />
        </IconButton>
      </TableCell>
      <TableCell><Chip color="primary" label={queijo.curtidas} /></TableCell>
      <TableCell>{moment(queijo.updatedDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
      <TableCell>
        <IconButton
          onClick={handleClick}
        >
          <EditIcon
            color="primary"
          />
        </IconButton>
        <IconButton
          onClick={() => onRemove(queijo._id)}
        >
          <DeleteIcon color="primary" />
        </IconButton>
      </TableCell>

    </TableRow>
  );
}

QueijoRow.propTypes = {
  queijo: PropTypes.instanceOf(Queijo).isRequired,
};

export default QueijoRow;