class User {
    constructor(data) {
      this._id = data?._id;
      this.nome = data?.nome;
      this.email = data?.email;
      this.role = data?.role;
      this.tokens = data?.Tokens || null;
    }
  
    /// Check if this is a valid user.
    isValid() {
      if (!this._id) return false;
      if (!this.nome || !this.role) return false;
      return true;
    }
  
    json() {
      return JSON.stringify(this);
    }
  }
  
  export default User;