import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { NavLink as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, ListItemButton, CircularProgress } from '@mui/material';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

import { dashboardData } from '../services/index';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [dashboard, setDashboard] = useState({
    loading: true,
    data: null
  });

  useEffect(() => {
    async function fetchProfile() {
      const loadedData = await dashboardData.dashboard();
      console.log(loadedData);
      setDashboard({
        loading: false,
        data: loadedData
      });
    }

    fetchProfile();
  }, []);

  if(dashboard.loading){
    return (
      <>
        <Helmet>
          <title> Dashboard | Painel Interativo do Queijo </title>
        </Helmet>
  
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Olá, Bem vindo(a) de volta.
          </Typography>
          <CircularProgress />
        </Container>
      </>
      ); 
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Olá, Bem vindo(a) de volta.
        </Typography>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={3}>
            <ListItemButton
              LinkComponent={RouterLink}
              href="/dashboard/queijos"
              sx={{ '&:hover': { backgroundColor: 'transparent' }}}
            >
              <AppWidgetSummary
                sx={{ width: '100%' }}
                title="Queijo(s)"
                color="primary"
                icon="twemoji:cheese-wedge"
                total={dashboard.data.queijos}
              />
            </ListItemButton>
          </Grid>
          <Grid item xs={3}>
            <ListItemButton
              LinkComponent={RouterLink}
              href="/dashboard/categorias"
              sx={{ '&:hover': { backgroundColor: 'transparent' }}}
            >
              <AppWidgetSummary
                sx={{ width: '100%' }}
                title="Categoria(s)"
                color="primary"
                icon="mdi:tag-multiple"
                total={dashboard.data.categorias}
              />
            </ListItemButton>
          </Grid>
          <Grid item xs={3}>
            <ListItemButton
              LinkComponent={RouterLink}
              href="/dashboard/receitas"
              sx={{ '&:hover': { backgroundColor: 'transparent' }}}
            >
              <AppWidgetSummary
                sx={{ width: '100%' }}
                title="Receita(s)"
                color="primary"
                icon="arcticons:recipe-keeper"
                total={dashboard.data.receitas}
              />
            </ListItemButton>
          </Grid>
          <Grid item xs={3}>
            <ListItemButton
              LinkComponent={RouterLink}
              href="/dashboard/harmonizacoes"
              sx={{ '&:hover': { backgroundColor: 'transparent' }}}
            >
              <AppWidgetSummary
                sx={{ width: '100%' }}
                title="Harmonizações"
                color="primary"
                icon="mdi:food-fork-drink"
                total={dashboard.data.harmonizacoes}
              />
            </ListItemButton>
          </Grid>
          <Grid item xs={3}>
            <ListItemButton
              LinkComponent={RouterLink}
              href="/dashboard/produtos"
              sx={{ '&:hover': { backgroundColor: 'transparent' }}}
            >
              <AppWidgetSummary
                sx={{ width: '100%' }}
                title="Produtos"
                color="primary"
                icon="dashicons:products"
                total={dashboard.data.produtos}
              />
            </ListItemButton>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
