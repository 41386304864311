import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { categoryService, queijoService } from '../../services';

import MyDropzone from '../../components/Dropzone';
import { validator } from '../../utils/index';

import { useSnack } from '../../hooks/index';

export default function QueijoFormPage() {

  const navigate = useNavigate();

  const { setSnack } = useSnack();

  const [error, setError] = useState();
  const [categoria, setCategoria] = useState([]);
  const [destaque, setDestaque] = useState(null);
  const [errors, setErrors] = useState({
    name: '',
    descricao: '',
    categorias: '',
  });
  const [categorias, setState] = useState({
    loading: true,
    categorias: []
  });

  console.log(categorias);

  useEffect(() => {
    if (error) return () => { };

    categoryService.getAll().then((categorias) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        categorias,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name');
    const descricao = data.get('descricao');
    const categorias = data.get('categorias');
    const nameError = validator.name(name, true);
    const descricaoError = validator.required(descricao, true);
    const categoriasError = validator.required(categorias, true);
    if (!nameError && !descricaoError && !categoriasError) {
      try {
        const token = await queijoService.create({
          name,
          descricao,
          categorias: categorias.split(','),
          imagem: destaque != null ? destaque.uploaded._id : null
        });
        navigate('/dashboard/queijos');
        return true;
      } catch (e) {
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        name: nameError,
        descricao: descricaoError,
        categorias: categoriasError,
      });
    }
  }

  const onDropFiles = async (fil) => {
    console.log("Chegou arquivos");
    setDestaque(fil[0]);
  }

  if (categorias.loading) {
    return (
      <>
        <Helmet>
          <title> Dashboard: Queijos | Painel Interativo do Queijo </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Adicionar Queijo
          </Typography>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
              textAlign: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Queijos | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Adicionar Queijo
        </Typography>
        <Box
          sx={{
            mt: 2,
            bgcolor: 'transparent',
          }}
          component="form"
          onSubmit={onSubmit}
        >
          <Grid
            container
            fullWidth
            spacing={5}
          >
            <Grid item xl={12}>
              <MyDropzone
                maxFiles={1}
                onDropFiles={onDropFiles}
               />
            </Grid>
            <Grid item xl={12}>
              <TextField
                name="name"
                label="Nome do Queijo"
                required
                fullWidth
                error={errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xl={12}>
              <TextField
                name="descricao"
                label="Descrição do Queijo"
                required
                type="textarea"
                multiline
                rows={4}
                fullWidth
                error={errors.descricao}
                helperText={errors.descricao}
              />
            </Grid>
            <Grid item xl={12}>
              <FormControl fullWidth>
                <InputLabel id="categorias">Categorias</InputLabel>
                <Select
                  labelId="categorias"
                  id="categorias-select"
                  value={categoria}
                  name="categorias"
                  multiple
                  required
                  label="Categorias"
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setCategoria(
                      // On autofill we get a stringified value.
                      typeof value === 'string' ? value.split(',') : value,
                    );
                  }}
                >
                  {categorias.categorias.map((cat) => <MenuItem value={cat._id}>{cat.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={12}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </>
  );
}
