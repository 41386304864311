class Category {
    constructor(data) {
      this._id = data?._id;
      this.name = data?.name;
      this.imagem = data?.imagem;
      this.updatedDate = data?.updatedDate;
    }
  
    /// Check if this is a valid user.
    isValid(checkId) {
      if (checkId && !this._id) return false;
      if (!this.name) return false;
      return true;
    }
  
    json() {
      return JSON.stringify(this);
    }
  }
  
  export default Category;