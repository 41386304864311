import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { harmonizacaoService, queijoService } from '../../services';

import MyDropzone from '../../components/Dropzone';
import { validator } from '../../utils/index';

import { useSnack } from '../../hooks/index';

export default function QueijoEditFormPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { setSnack } = useSnack();

  const [harmonizacao, setHarmonizacao] = useState({
    loading: true,
    data: []
  });
  const [error, setError] = useState();
  const [queijo, setQueijo] = useState([]);
  const [destaque, setDestaque] = useState(null);
  const [errors, setErrors] = useState({
    name: '',
    descricao: '',
    queijos: '',
  });
  const [queijos, setState] = useState({
    loading: true,
    queijos: []
  });

  console.log(harmonizacao);

  useEffect(() => {
    async function fetchProfile() {
      const loadedData = (await harmonizacaoService.get(id)) || {};
      console.log(loadedData);
      setHarmonizacao({
        loading: false,
        data: loadedData
      });
      setQueijo(loadedData.queijos);
      // setDestaque(loadedData.imagem._id);
    }

    fetchProfile();
  }, [id]);

  useEffect(() => {
    if (error) return () => { };

    queijoService.getAll().then((queijos) => {
      setState((oldState) => ({
        ...oldState,
        queijos,
        loading: false,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name');
    const descricao = data.get('descricao');
    const queijos = data.get('queijos');
    const nameError = validator.name(name, true);
    const descricaoError = validator.required(descricao, true);
    const queijosError = validator.required(queijos, true);
    if (!nameError && !descricaoError && !queijosError) {
      try {
        const token = await harmonizacaoService.update({
          _id: id,
          id,
          name,
          descricao,
          queijos: queijos.split(','),
          imagem: destaque != null ? destaque.uploaded._id : (harmonizacao.data.imagem ? harmonizacao.data.imagem._id : null)
        });
        navigate('/dashboard/harmonizacoes');
        return setSnack({ message: 'Harmonização atualizada!', type: 'success'  });;
      } catch (e) {
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        name: nameError,
        descricao: descricaoError,
        queijos: queijosError,
      });
    }
  }

  const onDropFiles = async (fil) => {
    console.log("Chegou arquivos");
    setDestaque(fil[0]);
  }

  if (queijos.loading || harmonizacao.loading) {
    return (
      <>
        <Helmet>
          <title> Dashboard: Harmonizações | Painel Interativo do Queijo </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Editar Harmonização
          </Typography>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
              textAlign: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Harmonizações | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Editar Harmonização
        </Typography>
        <Box
          sx={{
            mt: 2,
            bgcolor: 'transparent',
          }}
          component="form"
          onSubmit={onSubmit}
        >
          <Grid
            container
            fullWidth
            spacing={5}
          >
            <Grid item xl={12}>
              <MyDropzone
                maxFiles={1}
                onDropFiles={onDropFiles}
                previews={harmonizacao.data.imagem}
              />
            </Grid>
            <Grid item xl={12}>
              <TextField
                name="name"
                label="Nome da Harmonização"
                required
                fullWidth
                defaultValue={harmonizacao.data.name}
                error={errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xl={12}>
              <TextField
                name="descricao"
                label="Harmonização da Receita"
                required
                type="textarea"
                multiline
                rows={4}
                fullWidth
                defaultValue={harmonizacao.data.descricao}
                error={errors.descricao}
                helperText={errors.descricao}
              />
            </Grid>
            <Grid item xl={12}>
              <FormControl fullWidth>
                <InputLabel id="queijos">Queijos</InputLabel>
                <Select
                  labelId="queijos"
                  id="queijos-select"
                  value={queijo}
                  name="queijos"
                  multiple
                  required
                  label="Queijos"
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setQueijo(
                      // On autofill we get a stringified value.
                      typeof value === 'string' ? value.split(',') : value,
                    );
                  }}
                >
                  {queijos.queijos.map((cat) => <MenuItem value={cat._id}>{cat.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={12}>
              <Button variant="contained" type="submit">
                Atualizar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
