import { ENDPOINTS } from '../constants';
import { parseResponse } from '../utils';

class AuthService {
    async login (credentials)  {
    const response = await fetch(ENDPOINTS.AUTH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    return parseResponse(response);
  }
}

export default new AuthService();
