import { ENDPOINTS } from '../constants';
import { parseResponse, defaultHeaders } from '../utils';
import { Category } from '../models';

class CatergoryService {
    async getAll (query)  {
    const url = ENDPOINTS.parse(ENDPOINTS.CATEGORY, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Category);
  }
  
  async create(data) {
    const category = new Category(data);
    if (!category.isValid()) throw new Error('Invalid category');

    const response = await fetch(ENDPOINTS.CATEGORY, {
      method: 'POST',
      headers: defaultHeaders(),
      body: category.json(),
    });

    return parseResponse(response);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.CATEGORY_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, Category);
  }

  async update(data) {
    const category = new Category(data);
    if (!category.isValid(true)) throw new Error('Invalid category');

    const response = await fetch(ENDPOINTS.CATEGORY_ID(category._id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: category.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.CATEGORY_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }
}

export default new CatergoryService();
