class Queijo {
    constructor(data) {
      this._id = data?._id;
      this.name = data?.name;
      this.imagem = data?.imagem;
      this.categorias = data?.categorias;
      this.descricao = data?.descricao;
      this.destaque = data?.destaque;
      this.updatedDate = data?.updatedDate;
      this.curtidas = data?.curtidas;
    }
  
    /// Check if this is a valid user.
    isValid(checkId) {
      if (checkId && !this._id) return false;
      if (!this.name) return false;
      if (!this.descricao) return false;
      // if (!this.imagem) return false;
      if (!this.categorias) return false;
      return true;
    }
  
    json() {
      return JSON.stringify(this);
    }
  }
  
  export default Queijo;