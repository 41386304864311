import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  IconButton,
  TableCell,
  TableRow,
  Chip,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Receita } from '../../../models';

function ReceitaRow({ receita, onRemove }) {

  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(`/dashboard/receitas/${receita._id}`);
  };

  return (
    <TableRow>
      <TableCell>{receita.imagem && <Avatar src={receita.imagem.caminho} />}</TableCell>
      <TableCell>{receita.name}</TableCell>
      <TableCell>{receita.queijos.map((cat) => <Chip color="primary" label={cat.name} />)}</TableCell>
      <TableCell>{moment(receita.updatedDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
      <TableCell>
        <IconButton
          onClick={handleClick}
        >
          <EditIcon
            color="primary"
          />
        </IconButton>
        <IconButton
          onClick={() => onRemove(receita._id)}
        >
          <DeleteIcon color="primary" />
        </IconButton>
      </TableCell>

    </TableRow>
  );
}

ReceitaRow.propTypes = {
  receita: PropTypes.instanceOf(Receita).isRequired,
};

export default ReceitaRow;