import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Typography,
  Table,
  TableBody,
  Box,
  Button,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  TableFooter
} from '@mui/material';

import { receitaService } from '../../services';
import { DialogModal } from '../../components/dialog/index';

import { useSnack } from '../../hooks/index';

import { ReceitaRow } from './components';

export default function ReceitaPage() {

  const navigate = useNavigate();

  const { setSnack } = useSnack();

  const [page, setPage] = useState(0);
  const [remove, setRemove] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [receitas, setState] = useState({
    loading: true,
    reload: false,
    receitas: []
  });

  const [error, setError] = useState();

  console.log(receitas);

  useEffect(() => {
    if (error) return () => { };

    receitaService.getAll().then((receitas) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        reload: false,
        receitas,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error, receitas.reload]);

  const handleClick = () => {
    navigate('/dashboard/receitas/nova');
  };

  const handleRemove = async () => {
    setState((oldState) => ({
      ...oldState,
      reload: true,
    }));
    receitaService.delete(remove).then((success) => {
      if(success) {
        setSnack({
          type: 'success',
          message: 'Receita apagada com sucesso',
        });
        setRemove(false);
        navigate('/dashboard/receitas');
      } else {
        setSnack();
      }
    }).catch((error) => {
      setSnack({ message: error.message });
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  if (receitas.loading) {
    return (
      <>
        <Helmet>
          <title> Dashboard: Receitas | Painel Interativo do Queijo </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Receitas
          </Typography>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
              textAlign: 'center'
            }}
          >
            <CircularProgress />
          </Box>

        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Receitas | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Receitas
        </Typography>
        <Button variant="contained" onClick={handleClick}>
          + Adicionar
        </Button>
        <Box
          sx={{
            mt: 2,
            bgcolor: 'transparent',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Foto</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Queijos</TableCell>
                <TableCell>Úlima Atualização</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receitas.receitas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((receita) => (
                  <ReceitaRow receita={receita} onRemove={setRemove} />
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={receitas.receitas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Resultados por página"
                  labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                  onPageChange={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 50]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
        {
          remove && (
        <DialogModal
          handleClose={() => setRemove(false)}
          onAccept={handleRemove}
        >
          Deseja realmente remover essa receita?
        </DialogModal>
        )
        }
      </Container>
    </>
  );
}
