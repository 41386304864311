// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const iconMaterial = (name) => <SvgColor children={name} />;
const iconIconify = (name, color) => <Iconify icon={name} width={24} height={24} sx={{ color }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: iconIconify('material-symbols:team-dashboard', '#F19800'),
  },
  {
    title: 'queijos',
    path: '/dashboard/queijos',
    icon: iconIconify('twemoji:cheese-wedge'),
  },
  {
    title: 'categorias',
    path: '/dashboard/categorias',
    icon: iconIconify('mdi:tag-multiple', '#F19800'),
  },
  {
    title: 'receitas',
    path: '/dashboard/receitas',
    icon: iconIconify('arcticons:recipe-keeper', '#F19800'),
  },
  {
    title: 'harmonização',
    path: '/dashboard/harmonizacoes',
    icon: iconIconify('mdi:food-fork-drink', '#F19800'),
  },
  {
    title: 'Produtos',
    path: '/dashboard/produtos',
    icon: iconIconify('dashicons:products', '#F19800'),
  },
  /* {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
  {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: icon('ic_disabled'),
  }, */
];

export default navConfig;
