import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { produtoService, queijoService } from '../../services';

import MyDropzone from '../../components/Dropzone';
import { validator } from '../../utils/index';

import { useSnack } from '../../hooks/index';

export default function ProdutoFormPage() {

  const navigate = useNavigate();

  const { setSnack } = useSnack();

  const [error, setError] = useState();
  const [queijo, setQueijo] = useState([]);
  const [destaque, setDestaque] = useState(null);
  const [errors, setErrors] = useState({
    name: '',
    queijos: '',
  });
  const [queijos, setState] = useState({
    loading: true,
    queijos: []
  });

  console.log(queijos);

  useEffect(() => {
    if (error) return () => { };

    queijoService.getAll().then((queijos) => {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        queijos,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name');
    const queijos = data.get('queijos');
    const nameError = validator.name(name, true);
    const queijosError = validator.required(queijos, true);
    if (!nameError && !queijosError) {
      try {
        await produtoService.create({
          name,
          queijos: queijos.split(','),
          imagem: destaque != null ? destaque.uploaded._id : null
        });
        navigate('/dashboard/produtos');
        return true;
      } catch (e) {
        return setSnack({ message: e.message });
      }
    } else {
      return setErrors({
        name: nameError,
        queijos: queijosError,
      });
    }
  }

  const onDropFiles = async (fil) => {
    console.log("Chegou arquivos");
    setDestaque(fil[0]);
  }

  if (queijos.loading) {
    return (
      <>
        <Helmet>
          <title> Dashboard: Produtos | Painel Interativo do Queijo </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Adicionar Produto
          </Typography>
          <Box
            sx={{
              mt: 2,
              bgcolor: 'transparent',
              textAlign: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Produtos | Painel Interativo do Queijo </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Adicionar Produto
        </Typography>
        <Box
          sx={{
            mt: 2,
            bgcolor: 'transparent',
          }}
          component="form"
          onSubmit={onSubmit}
        >
          <Grid
            container
            fullWidth
            spacing={5}
          >
            <Grid item xl={12}>
              <MyDropzone
                maxFiles={1}
                onDropFiles={onDropFiles}
               />
            </Grid>
            <Grid item xl={12}>
              <TextField
                name="name"
                label="Nome do Produto"
                required
                fullWidth
                error={errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xl={12}>
              <FormControl fullWidth>
                <InputLabel id="queijos">Queijos</InputLabel>
                <Select
                  labelId="queijos"
                  id="queijos-select"
                  value={queijo}
                  name="queijos"
                  multiple
                  required
                  label="Queijos"
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setQueijo(
                      // On autofill we get a stringified value.
                      typeof value === 'string' ? value.split(',') : value,
                    );
                  }}
                >
                  {queijos.queijos.map((cat) => <MenuItem key={cat._id} value={cat._id}>{cat.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={12}>
              <Button variant="contained" type="submit">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>

      </Container>
    </>
  );
}
